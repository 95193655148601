import React from "react";
import { motion } from "framer-motion";
import sanityClient from "../client.js";
import BlockContent from '@sanity/block-content-to-react';
import Cookies from 'js-cookie';

const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.9] };

class Popup extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            popupTitle: "",
            popupText: [],
            popupButtonTitle: "Compris",
            popupButtonLink: "#",
        };

        this.serializers = {
            types: {
                code: props => (
                    <pre data-language={props.node.language}>
                        <code>{props.node.code}</code>
                    </pre>
                )
            }
        };
    }

    componentDidMount() {
        sanityClient.fetch(`*[_type == 'siteSettings'][0]`)
            .then(data => {
                this.setState({
                    popupTitle: data.popupTitle,
                    popupText: data.popupText,
                    popupButtonTitle:  data.popupButtonTitle ?? "Compris !",
                    popupButtonLink:  data.popupButtonLink ?? "#",
                });
                console.log(this.state)
            })
            .catch(console.error);
    }

    render() {
        return (
            <motion.div className="modal"
                key="modal" 
                initial={{ opacity: 0, y: -100 }} 
                animate={{ opacity: 1, y: 0, transition: transition }}
                exit={{ opacity: 0, y: 100 }} 
            >
                <div className="container modal-content">
                    <div className="row align-items-center justify-content-center my-5">
                        <div className="col-lg-5">
                            <h1 className="font-weight-light text-center my-5">{this.state.popupTitle}</h1>
                            <div>
                                <BlockContent blocks={this.state.popupText} projectId="552cpgqt" dataset="production" serializers={this.serializers} />
                            </div>
                            <div className="bf-button justify-content-center">
                                <a href={this.state.popupButtonLink} onClick={() => { Cookies.set('disablePopupCookie', 'true', { expires: 1 }) }}>{this.state.popupButtonTitle}</a>
                            </div>
                        </div>
                    </div>
                    <div className="close" onClick={() => { Cookies.set('disablePopupCookie', 'true', { expires: 1 }); document.querySelector('.modal').style.display = 'none'; }}>X</div>
                </div>
            </motion.div>
        );
    }
}
export default Popup;